<template>
  <div>
    <div class="mb-1">Instruções</div>
    <div v-show="isTypeMonthSelected">
      <v-icon color="primary"> event_available </v-icon>
      <span class="ml-2 caption">Há agendamentos criados para o dia</span>
    </div>
    <div v-show="isTypeMonthSelected">
      <v-icon color="primary"> touch_app </v-icon>
      <span class="ml-2 caption">
        Pressione no dia para ver os agendamentos
      </span>
    </div>
    <div v-show="isTypeDaySelected">
      <v-icon color="primary"> touch_app </v-icon>
      <span class="ml-2 caption">
        Pressione no horário vago para criar ou editar um agendamento
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Legends",
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    isTypeMonthSelected() {
      return this.type === "month";
    },
    isTypeDaySelected() {
      return this.type === "day";
    },
  },
};
</script>

<style scoped></style>
